html {
  color: #ddd;
  font-size: 1em;
  line-height: 1.4;
}

body {
  text-align: center;
  background-color: #2b2b2b;
  font-family: "Special Elite", "Times New Roman", Times, Georgia, serif;
}

.container {
  margin: 0 auto;
  padding-top: 20px;
  width: 320px;
}

h1 {
  font-weight: normal;
  margin-bottom: 0;
}

h2 {
  margin-bottom: 0.2rem;
}

header {
  display: inline-block;
  margin: 0 auto;
}

header img {
  width: 80%;
}

header header .instruction {
  text-align: right;
}

.input-area {
  width: 90%;
  border-radius: 8px;
  border: solid 1px #444;
  background: #3a3a3a;
  margin: 0 auto 2rem;
  padding: 1.5rem 0;
}

.output .display {
  margin: 20px;
  font-size: 1.4rem;
  text-align: left;
}

.output .clear-button {
  padding: 0.6rem;
  background-color: #666;
  border: solid 1px #444;
  border-radius: 4px;
}

.output .clear-button:focus {
  outline: 0;
}

.small {
  font-size: 0.7rem;
}

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

textarea {
  resize: vertical;
}
